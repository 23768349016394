<template>
    <div class="charge">
        <div class="avatar">
            <van-image class="avatar-image" fit="cover" :src="getImg($store.state.memberUser.picHeadUrl)" alt="">
                <template v-slot:error><van-image class="avatar-image" fit="cover" alt=""/></template>
            </van-image>
            <div class="name">
                <span>{{$store.state.memberUser.memberName || '***'}}</span><sub>{{$store.state.personTypeName}}</sub>
            </div>
            <div class="bills" @click="goBill">
                账单明细
            </div>
        </div>
        <div class="charge-content">
            <div class="item" v-for="(item, i) in chargeList" :key="i" :class="{'active': item.select}" @click="changeSelect(item)">
                <div>{{item.price | money}}</div>
                <div>售价:{{item.realPrice | money}}</div>
                <i class="iconfont icon-jiaobiaoxuanzhong_o"></i>
            </div>
        </div>

        <!-- <check-stand :selectMoney="selectMoney" :payType="payType" :check-stand="charge" @onSubmit="onSubmit" /> -->
        <my-btn :btnStr="'立即支付:￥'+selectMoney" @onSubmit="submit" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            avatar: require('@/assets/img/horse1.jpg'),
            chargeList: [],
            payType: [
                {name: '微信支付',  checked: false},
            ],
            charge: {
                show: false
            },
            selectMoney: '0',
            radio: 0
        }
    },
    filters:{
        money(val) {
            return val + '元'
        }
    },
    methods: {
        goBill() {
            this.$router.push('/center/bill')
        },
        changeSelect(data) {
            this.chargeList.forEach(ele=>{
                if(ele.price === data.price){
                    this.$set(ele, "select", true)
                    this.selectMoney = (ele.realPrice-0).toFixed(2)
                }else{
                    this.$set(ele, "select", false)
                }
            })
        },
        submit() {
            this.$api.post('member/bill/memberRecharge/memberRecharge-save', {payAmount: this.selectMoney}).then(res=>{
                let url = window.encodeURIComponent(`${window.location.origin}/#/center/pay?orderId=${res.id}&orderType=${res.orderPayInfoVO.orderType}`)
                window.location.href = `${res.orderPayInfoVO.jumpGetOpenIdUrl}?returnUrl=${url}`
            })

        },
        getLevel() {
            //debugger
            this.$help.loading()
            this.$api.post('sys/sysList/sysList-list-byName', {name: '充值金额设定'}).then(res=>{
                //console.log(res)
                if(res.length !== 0){
                    res.forEach(ele=>{
                        //console.log(ele)
                        if(ele.orderIndex === 1){
                            this.chargeList.push({
                                price: ele.itemText,
                                realPrice: ele.itemText,
                                select: true
                            })
                        }else{
                            this.chargeList.push({
                                price: ele.itemText,
                                realPrice: ele.itemText,
                                select: false
                            })
                        }
                        
                    })
                    this.chargeList.forEach(ele=>{
                        if(ele.select){
                            this.selectMoney = (ele.realPrice-0).toFixed(2)
                        }
                    })
                }
            })
            .finally(this.$help.loaded)
        },
        getMemberUser() { 
            this.$store.dispatch('getMemberUser').then()
        },
    },
    mounted() {
        this.getLevel()
        this.getMemberUser()
    }
}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .charge{
        .avatar{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 20px;
            margin: 20px;
            background-color: #fff;
            box-shadow: 0 0 9px 6px $boxShadow;
            position: relative;
            border-radius: 19px;
            &-image{
                height: 80px;
                width: 80px;
                border-radius: 50%;
                img{
                    border-radius: 50%;
                }
            }
            .name{
                font-size: 14px;
                font-weight: bold;
                padding-top: 5px
                ;
                sub{
                    font-weight: 400;
                    color: $main;
                    padding-left: 5px;
                }
            }
            .bills{
                position: absolute;
                top: 20px;
                right: 20px;
                font-size: 14px;
            }
        }
        &-content{
            margin: 10px;
            padding: 10px;
            display: flex;
            flex-wrap: wrap;
            background-color: #fff;
            box-shadow: 0 0 9px 6px $boxShadow;
            border-radius: 19px;
            .item{
                width: 80px;
                padding: 8px;
                border: 1px solid $main;
                margin: 5px;
                position: relative;
                border-radius: 5px;
                i{
                    position: absolute;
                    bottom: -11px;
                    right: -3px;
                    color: $main;
                    font-size: 22px;
                    font-weight: bold;
                    display: none;
                    //background-color: $main;
                }
                div{
                    text-align: center;
                }
            }
            .active{ 
                background: $backgroundColor;
                border-radius: 5px 5px 0 5px;
                i{
                    display: block;
                }
            }
        }
    }
    

</style>